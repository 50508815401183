import {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {classNames} from '@commonFunction';

export const RevealMenuTypes = {
  BEMAIL: 'businessemail',
  SEMAIL: 'personalemail',
  PHONE: 'directdial',
  ALL: 'all',
};

export const RevealMenuList = [
  {title: 'Reveal Business Emails', type: RevealMenuTypes.BEMAIL},
  {title: 'Reveal Secondary Emails', type: RevealMenuTypes.SEMAIL},
  {title: 'Reveal Phone Number', type: RevealMenuTypes.PHONE},
  {title: 'Reveal All', type: RevealMenuTypes.ALL},
];

function MenuLabel(props) {
  let {title, connected} = props;

  return (
    <div
      className={classNames(
        'group w-full rounded-lg p-1 ',
        'hover:bg-gray-50 hover:cursor-pointer hover:scale-105 transition ease-in duration-100',
      )}
      onClick={() => {
        props.onSelectedMenu(props.type);
      }}>
      <div
        className={classNames('justify-start items-start gap-1 inline-flex')}>
        <div className="px-2 py-1 rounded-2xl justify-start items-center gap-1 flex">
          <div
            className={classNames(
              'text-center text-xs leading-none',
              'group-hover:text-primary',
            )}>
            {title}
          </div>
        </div>
      </div>
    </div>
  );
}

function MenuDropdown(props) {
  let list = [];

  list = RevealMenuList;

  return (
    <div className="h-auto p-2 bg-white rounded-lg shadow-md border border-gray-100 flex-col justify-start items-start gap-3 inline-flex">
      <div className="self-stretch justify-start items-start gap-6 inline-flex">
        <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
          {list.map(val => (
            <MenuLabel
              connected={
                val.type === RevealMenuTypes.BEMAIL ? true : props[val.type]
              }
              key={val.type}
              {...val}
              onSelectedMenu={props.onSelectedMenu}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  const {totalcount, pages, data} = state.csvEnrichList;
  return {
    isLoggedIn: state.isLoggedIn,
    user: state.user,
    session: state.session,
    appuserData: state.appuserData,
  };
};

export default connect(mapStateToProps, {})(MenuDropdown);

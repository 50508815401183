import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import toast, {Toaster} from 'react-hot-toast';
import _ from 'lodash';
import renderHTML from 'react-render-html';
import LocaleStrings from '@language';
import {BaseComponent, LABEL_POSITION_TOP} from '@baseComponent';
import {classNames} from '@commonFunction';
import {Card, CardHeader, CardBody, Input, Col} from 'reactstrap';
import BaseLoader from '@baseLoader';
// Import constant
import {validatepassword} from '@constant';
import LoginErrorBar from '../starter/errorbar';
import {createpassword} from '@mainActions';
import {fetchinvitedappuser, updatinvitedappuser} from '@sidebarActions';
import BtnLoaderSvg from '@btnLoaderSvg';

class CreatePassword extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      showerror: false,
      showsuccess: false,
      alertMessage: '',
      params: {},
      show: false,
      show1: false,
      isBtnDisable: false,
      btnLoader: false,
    };

    // All Bind Functions
    this.changeShow = this.changeShow.bind(this);
    this.changeShow1 = this.changeShow1.bind(this);
  }

  componentDidMount() {
    let {session} = this.props;
    var url_string = this.props.router.location.search;
    var query = url_string.split('?')[1];

    var params = {};

    if (query) {
      var vars = query.split('&');
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        params[pair[0]] = decodeURIComponent(pair[1]);
      }
      if (params && params.email && params.token) {
        this.props.fetchinvitedappuser(session, params, callback => {
          if (callback && callback.success == 1) {
            this.setState({loading: false});
          } else {
            this.setState({loading: false});
          }
        });
      }
      // console.log("params", params);
      this.setState({params: params});
    }
  }

  //   componentDidMount() {
  //     document.title = LocaleStrings.reset_password_title;

  //     var url_string = this.props.router.location.search;
  //     console.log('url_string => ', url_string);
  //     var query = url_string.split('?')[1];

  //     var params = {};
  //     if (query) {
  //       var vars = query.split('&');
  //       for (var i = 0; i < vars.length; i++) {
  //         var pair = vars[i].split('=');
  //         params[pair[0]] = decodeURIComponent(pair[1]);
  //       }
  //       // console.log("params", params);
  //       this.setState({params: params});
  //     }
  //   }

  changeShow() {
    let {show} = this.state;
    this.setState({show: !show});
  }

  changeShow1() {
    let {show1} = this.state;
    this.setState({show1: !show1});
  }

  /* FORM SUBMIT */
  onFormSubmit(values) {
    let {session, user, invitedappuserData} = this.props;

    if (values.password && values.password.length < 8) {
      this.setState({btnLoader: false});
      toast.error(LocaleStrings.reset_password_length_error);
      return false;
    }

    if (
      values.password == values.confirm_password &&
      this.state.params.token != '' &&
      this.state.params.email != ''
    ) {
      values.email = this.state.params.email ? this.state.params.email : '';

      let obj = {};
      obj.clientid = invitedappuserData.data[0].clientid;
      obj.appuserid = invitedappuserData.data[0].appuserid;
      obj.isverified = 1;
      obj.isactive = 1;
      obj.invitestatus = 'accepted';

      this.setState({isBtnDisable: true, btnLoader: true});

      this.props.createpassword(values, feedback => {
        //console.log('feedback', feedback);
        if (feedback.success === 0) {
          this.setState({btnLoader: false, isBtnDisable: false});
          toast.error(LocaleStrings.something_went_wrong);
        } else {
          this.props.updatinvitedappuser(session, obj, response => {
            if (response.success === 1) {
              toast.success(LocaleStrings.password_create_acceptinvite_success);
              this.setState({isBtnDisable: false});
              this.setState({btnLoader: false});
              this.props.router.navigate('/');
              this.props.reset();

              document.title = LocaleStrings.main_title;
            } else {
              // If Un successfull Registration
              toast.error(LocaleStrings.something_went_wrong);
              this.setState({isBtnDisable: false});
              this.setState({btnLoader: false});
              this.props.reset();
              return false;
            }
          });
        }
      });
    }
  }

  render() {
    var {handleSubmit, btnLoading, language, invitedappuserData} = this.props;
    //console.log('invitedappuserData', invitedappuserData);
    let isverified = 0;
    let isactive = 0;
    if (
      invitedappuserData &&
      invitedappuserData.data &&
      invitedappuserData.data.length > 0
    ) {
      isverified = invitedappuserData.data[0].isverified;
      isactive = invitedappuserData.data[0].isactive;
    }

    console.log('btnLoader:=', this.state.btnLoader);
    return (
      <>
        {this.state.loading ? (
          <>
            <BaseLoader data={this.state.loaderDesc} />
          </>
        ) : (
          <>
            {invitedappuserData &&
            invitedappuserData.data &&
            invitedappuserData.data.length > 0 ? (
              <>
                {(isactive == 1 || isactive == '1') &&
                isverified == 1 &&
                isverified == '1' ? (
                  <div className="center-item lg:my-48 text-lg text-ternary">
                    {LocaleStrings.acceptinvite_alreadyactiveuser}
                  </div>
                ) : (
                  <>
                    <Col lg="5" md="7">
                      <Card
                        className="bg-secondary shadow border-0"
                        style={{boxShadow: 'none'}}>
                        <CardHeader className="bg-transparent">
                          <div className="text-muted text-center">
                            {LocaleStrings.reset_pwd_to}
                          </div>
                        </CardHeader>
                        <CardBody
                          className=""
                          style={{
                            background: '#fff',
                            boxShadow: 'none',
                            border: 'none',
                          }}>
                          {this.state.showerror ? (
                            <LoginErrorBar
                              alertMessage={this.state.alertMessage}
                            />
                          ) : (
                            ''
                          )}

                          {this.state.showsuccess ? (
                            <div className="alert alert-success animated fadeIn text-center">
                              {this.state.alertMessage}
                            </div>
                          ) : (
                            ''
                          )}

                          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                            <div
                              className="bg-primaryHover py-8 px-4 shadow sm:rounded-lg sm:px-10 "
                              style={{marginTop: '25%'}}>
                              <div className="mb-12 text-center">
                                <img
                                  // className="h-6"
                                  className="h-12 mx-auto mb-6"
                                  // src="../../images/mainlogo.png"
                                  src="../../images/kipplo-logo.webp"></img>
                                <div className="text-hint font-normal text-ternary">
                                  {LocaleStrings.create_password_reset_here}
                                </div>
                              </div>
                              <div className="space-x-4 ">
                                <form
                                  className="space-y-2"
                                  onSubmit={handleSubmit(
                                    this.onFormSubmit.bind(this),
                                  )}>
                                  <>
                                    <div>
                                      <div className="my-5 block">
                                        <div className="mt-5">
                                          <p> {LocaleStrings.email}</p>
                                          <Input
                                            value={
                                              this.state.params.email
                                                ? this.state.params.email
                                                : ''
                                            }
                                            type="email"
                                            name="email"
                                            rule={this.validateEmail}
                                            readonly
                                            style={{
                                              width: '100%',
                                              borderRadius: '6px',
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="pb-4">
                                      <Field
                                        name="password"
                                        label={LocaleStrings.password}
                                        placeholder={LocaleStrings.password}
                                        type="password"
                                        component={this.renderFieldText}
                                        mandatory="true"
                                        show={this.state.show}
                                        setShow={this.changeShow}
                                        labelposition={LABEL_POSITION_TOP}
                                        classNameLabel="text-sm font-normal text-secondary"
                                        className="cust-pr-20"
                                      />
                                    </div>
                                    <Field
                                      name="confirm_password"
                                      label={
                                        LocaleStrings.create_password_field_confirm_password
                                      }
                                      placeholder={
                                        LocaleStrings.create_password_field_confirm_password
                                      }
                                      type="password"
                                      component={this.renderFieldText}
                                      mandatory="true"
                                      show={this.state.show1}
                                      setShow={this.changeShow1}
                                      labelposition={LABEL_POSITION_TOP}
                                      classNameLabel="text-sm font-normal text-secondary"
                                      className="cust-pr-20"
                                    />
                                    <div className="pt-4">
                                      <button
                                        type="submit"
                                        //disabled={this.state.isBtnDisable}
                                        className={classNames(
                                          this.state.isBtnDisable
                                            ? 'cursor-not-allowed'
                                            : '',
                                          'btn-primary',
                                        )}>
                                        <BtnLoaderSvg
                                          loader={this.state.btnLoader}
                                        />
                                        {LocaleStrings.create_password_btn}
                                      </button>
                                    </div>
                                  </>
                                </form>
                              </div>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Toaster />
                  </>
                )}
              </>
            ) : (
              <div className="center-item lg:my-48 text-lg text-ternary">
                {LocaleStrings.acceptinvite_appusernotfound}
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

function validate(values) {
  var errors = {};
  var password = values['password'];

  var requiredFields = ['password', 'confirm_password'];
  requiredFields.forEach(field => {
    if (!values[field] || values[field].trim() === '') {
      errors[field] = 'Required';
    }
  });

  let passwordErrorMessage =
    '<div style="width: 350px; display: table;margin-top:10px;"><div style="display: table-row"><div style=" display: table-cell;">&#8226; One lowercase character</div><div style="display: table-cell;">&#8226; Atleast one number</div></div></div><div style="width: 350px; display: table;margin-top:10px"><div style="display: table-row"><div style=" display: table-cell;">&#8226; One uppercase character</div><div style="display: table-cell;"> &#8226; 8 characters minimum</div></div></div>';
  if (password && !validatepassword(password)) {
    errors['password'] = renderHTML(passwordErrorMessage);
  }

  return errors;
}

var mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  language: state.language,
  invitedappuserData: state.invitedappuserData,
});

export default reduxForm({
  validate,
  form: 'createPasswordForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  connect(mapStateToProps, {
    createpassword,
    fetchinvitedappuser,
    updatinvitedappuser,
  })(CreatePassword),
);

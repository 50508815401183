// import IconChevronDown from './icons/chevrondown';
import React, {createRef, Fragment, useState} from 'react';
import Menudropdown from './menudropdown';
// import Portal from '@/common/Portal';
import {PortalWithState} from 'react-portal';
import TooltipPopover from '@/common/TooltipPopover';
import {ChevronDownIcon} from '@heroicons/react/solid';

function RevealMenu(props) {
  const [coords, setCoords] = useState({}); // takes current button coordinates

  const btnRef = React.createRef();
  const updateTooltipCoords = _ => {
    const rect = btnRef.current.getBoundingClientRect();
    setCoords({
      left: rect.x + 200.0, // add half the width of the button for centering
      top: rect.y + 32.0, // add scrollY offset, as soon as getBountingClientRect takes on screen coords
    });
  };

  const menuFilterRef = createRef();

  return (
    <div className="z-10 relative " ref={menuFilterRef}>
      <PortalWithState closeOnOutsideClick closeOnEsc>
        {({openPortal, closePortal, isOpen, portal}) => (
          <Fragment>
            <div
              ref={btnRef}
              onClick={e => {
                updateTooltipCoords(e.target);
                openPortal(e);
              }}>
              <span className="isolate inline-flex rounded-md shadow-sm">
                <button
                  type="button"
                  className="relative -ml-px inline-flex rounded-md items-center bg-primary px-2 py-2 text-custWhite ring-1 ring-inset ring-gray-300 hover:opacity-90 focus:z-10">
                  Reveal
                  <ChevronDownIcon
                    className="h-4 w-4 text-white ml-2"
                    aria-hidden="true"
                  />
                </button>
              </span>
            </div>
            {portal(
              <TooltipPopover
                width={200}
                coords={coords}
                direction={'left'}
                updateTooltipCoords={() =>
                  updateTooltipCoords(menuFilterRef.current.buttonNode)
                }>
                <Menudropdown
                  {...props}
                  onSelectedMenu={type => {
                    // setToggleOpenMenu(false);
                    closePortal();
                    props.onSelectedMenu(type);
                  }}
                />
              </TooltipPopover>,
            )}
          </Fragment>
        )}
      </PortalWithState>
    </div>
  );
}

export default RevealMenu;

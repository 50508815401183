import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {classNames} from '@commonFunction';

import IconFav from './icons/fav';
import SocialLinks from './components/sociallinks';
import {
  FILTER_TYPE_PEOPLE,
  addCRMLead,
  addPersonTolist,
  revealAllContact,
  tooglePeopleFav,
  updateFavPeople,
  updatePeople,
  zapierHook,
} from '../../actions';
import IconFaved from './icons/faved';
import {toTitleCase} from '../../../../../common/constants';
import CompanyBlock from './components/companyblock';

import _ from 'lodash';

import ContactDetails from './components/contactdetails';
import IconDelete from '../../../mylist/icons/delete';

function PeopleCell(props) {
  const {selected, onCheckUnCheck, filterType} = props;

  const [person, setPerson] = useState(props.person);

  const {
    contactname,
    jobtitle,
    company,
    linkedin,
    facebook,
    twitter,
    isfav,
    revealed,
    addedtolist,
    listid,
    peopleid,
    new_contact_data_available,
    new_company_data_available,
    exported_to_webhook,
    exported_to_salesforce,
    exported_to_zoho,
  } = person;

  useEffect(() => {
    if (props.person !== person) {
      setPerson(props.person);
    }
  }, [props.person]);

  return (
    <tr
      key={peopleid}
      className={classNames(
        'hover:bg-gray-50 hover:cursor-pointer',
        selected ? 'bg-gray-50' : undefined,
      )}>
      {filterType === FILTER_TYPE_PEOPLE ? (
        <td className="group relative px-2 py-2">
          {selected && (
            <div className="absolute inset-y-0 left-0 w-0.5 bg-primary" />
          )}
          <input
            type="checkbox"
            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-500 text-primary group-hover:cursor-pointer"
            value={contactname}
            checked={selected}
            onChange={e => onCheckUnCheck(e.target.checked)}
          />
        </td>
      ) : null}
      <td
        className={classNames('group py-2 pr-3 relative px-7')}
        onClick={() => {
          onCheckUnCheck(!selected);
        }}>
        <div className="relative flex gap-2">
          <div
            className={classNames(
              'whitespace-nowrap text-xs font-medium w-fit truncate',
              selected ? 'text-primary' : 'text-gray-900',
            )}>
            {contactname}
          </div>
          <div>
            <SocialLinks
              linkedin={linkedin}
              twitter={twitter}
              facebook={facebook}
              exported_to_zoho={exported_to_zoho}
              exported_to_salesforce={exported_to_salesforce}
              exported_to_webhook={exported_to_webhook}
            />
          </div>
        </div>
      </td>
      <td className="group whitespace-wrap px-3 py-2">
        <div className="text-xs text-gray-500">{toTitleCase(jobtitle)}</div>
      </td>
      <td className="whitespace-nowrap px-3 text-xs text-gray-500 justify-items-center">
        <ContactDetails {...props} />
      </td>
      <td className="w-1/3 px-3 py-2">
        <CompanyBlock company={company} />
      </td>
      <td className="group px-3 py-2">
        <div className="relative flex">
          <div className="text-xs text-gray-500 w-fit">
            {toTitleCase(company.industry)}
          </div>
        </div>
      </td>

      <td className="whitespace-nowrap py-2 pl-3 pr-4">
        <div className="group flex gap-2 justify-end relative pr-4">
          <div
            onClick={() => {
              const newpeople = {...props.person, isfav: !isfav};

              if (filterType === FILTER_TYPE_PEOPLE) {
                const index = props.filteredPeople.data.findIndex(
                  t => t.peopleid === props.person.peopleid,
                );
                setPerson(newpeople);
                props.filteredPeople.data[index] = newpeople;
                props.updatePeople(props.filteredPeople);
                const params = {
                  action: isfav ? 'removefav' : 'addfav',
                  peopleid: newpeople.peopleid,
                };
                props.tooglePeopleFav(props.session, params, res => {
                  // console.log('Fav => ', res);
                });
              } else {
                props.onRemoveFav(newpeople);
              }
            }}>
            {isfav ? (
              filterType === FILTER_TYPE_PEOPLE ? (
                <IconFaved />
              ) : (
                <IconDelete />
              )
            ) : (
              <IconFav />
            )}
          </div>
        </div>
      </td>
    </tr>
  );
}

function mapStateToProps(state, ownProps) {
  var {session, user, savedFilters} = state;
  return {
    session,
    user,
    savedFilters,
    availableLists: state.availableLists,
    searchSelectedSubMenu: state.searchSelectedSubMenu,
    filteredPeople:
      ownProps.filterType === FILTER_TYPE_PEOPLE
        ? state.filteredPeople
        : state.filteredFavPeople,
    crmDetails: state.crmDetails,
  };
}

export default connect(mapStateToProps, {
  updatePeople,
  tooglePeopleFav,
  addPersonTolist,
  updateFavPeople,
  addCRMLead,
  revealAllContact,
  zapierHook,
})(PeopleCell);

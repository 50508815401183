import {useCallback, useEffect, useState} from 'react';
import LocaleStrings from '../../../../languages';
import IconCompany from '../icons/company';
import IconCSV from '../icons/csv';
import IconPeople from '../icons/people';
import {connect} from 'react-redux';
import IconCSVFile from '../icons/csvfile';
import IconReupload from '../icons/reupload';
import StandardFields from './standardfields';
import {useFilePicker} from 'use-file-picker';
import {toggleCSVSelectionPopup, uploadCSV} from '../../actions';
import Mapping from './mapping';
import SkeletonLoader from '../../../../../common/skeletonloader';
import {ExclamationCircleIcon} from '@heroicons/react/outline';

function UploadCSV(props) {
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState(null);

  const {openFilePicker, filesContent, loading, plainFiles} = useFilePicker({
    accept: ['.csv'],
  });

  const resetError = () => {
    setError(null); // Reset the error state
  };

  useEffect(() => {
    if (!loading && filesContent.length > 0) {
      resetError();
      props.toggleCSVSelectionPopup({
        ...props.toggleCSVSelectionPopupData,
        file: filesContent[0],
        originalFile: plainFiles[0],
      });
      setFetching(true);
    }
  }, [filesContent, loading]);

  useEffect(() => {
    if (fetching) {
      let formdata = new FormData();
      formdata.append('csv', props.toggleCSVSelectionPopupData.originalFile);

      formdata.append(
        'type',
        props.section === 'people' ? 'contact' : 'company',
      );

      props.uploadCSV(props.session, formdata, response => {
        setFetching(false);
        if (response.success) {
          resetError(); // Clear errors on success
        } else {
          setError(response.message || 'An error occurred during upload.');
        }
      });
    }
  }, [fetching]);

  const {rows, headers, name} = props.csvUploadInfo;

  //console.log('csvUploadInfo', props.csvUploadInfo);

  return (
    <div className="flex gap-4 flex-col content-center px-4 py-2">
      <div className="flex flex-row gap-2 items-center">
        <div className="w-10 h-10 p-2 bg-secondary rounded-full justify-center items-center flex">
          <div className="justify-center items-center flex">
            {props.section === 'people' ? <IconPeople /> : <IconCompany />}
          </div>
        </div>
        <div>
          <div className="text-lg font-semibold">
            {props.section === 'people'
              ? LocaleStrings.enrichment.people.title
              : LocaleStrings.enrichment.company.title}
          </div>
          <div className="text-gray-600 text-xs">
            {props.section === 'people'
              ? LocaleStrings.enrichment.people.subTitle
              : LocaleStrings.enrichment.company.subTitle}
          </div>
        </div>
      </div>
      {fetching ? (
        <div
          className="flex justify-center"
          style={{height: 'calc(100vh / var(--zoom) - 200px)'}}>
          <SkeletonLoader enrich />
        </div>
      ) : error ? (
        <div
          className="flex flex-col gap-4"
          style={{height: 'calc(100vh / var(--zoom) - 200px)'}}>
          <div
            role="alert"
            className="bg-red-50 border border-red-400 text-red-700 rounded-lg p-4 flex items-start space-x-4">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
            <div>
              <p className="font-semibold pb-1">Upload Error</p>
              <p className="text-sm pb-4">{error}</p>
              <button
                className="btn-primary flex gap-2"
                style={{width: '146px'}}
                onClick={() => {
                  openFilePicker();
                }}>
                <div>
                  <IconReupload color="#ffffff" />
                </div>
                <div className="text-xs">
                  {LocaleStrings.enrichment.reupload}
                </div>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="flex flex-col gap-4"
          style={{height: 'calc(100vh / var(--zoom) - 200px)'}}>
          <div className="border border-gray-200 rounded-xl shadow-sm w-full px-4 py-2">
            <div className="flex justify-between">
              <div className="flex flex-row justify-center items-center">
                <div>
                  <IconCSVFile />
                </div>
                <div className="flex flex-col justify-center ml-2">
                  <div className="text-xs text-primary font-semibold">
                    {name}
                  </div>
                  <div className="text-xs text-gray-500">{`${rows} ${LocaleStrings.enrichment.rows}`}</div>
                </div>
              </div>
              <div>
                <button
                  className="flex gap-2 border border-gray-200 p-2 rounded-md hover:opacity-90"
                  onClick={() => {
                    //   setHasFile(false);
                    openFilePicker();
                  }}>
                  <div>
                    <IconReupload />
                  </div>
                  <div className="text-xs">
                    {LocaleStrings.enrichment.reupload}
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div className="border border-gray-200 rounded-xl shadow-md w-full px-4 pb-4 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-transparent scrollbar-thumb-rounded-full">
            {headers ? <Mapping headers={headers} /> : null}
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  appuserData: state.appuserData,
  toggleCSVSelectionPopupData: state.csvSelectionPopupData,
  section: state.csvSelectionPopupData.section,
  csvUploadInfo: state.csvUploadInfo,
});

export default connect(mapStateToProps, {toggleCSVSelectionPopup, uploadCSV})(
  UploadCSV,
);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import {convertDateIntoLocal, classNames} from '@commonFunction';

import LocaleStrings from '@language';
import {PROFESSIONAL_YEARLY, TRIALPLANID} from '@constant';
import TransactionHistoryItem from './transactionhistory-items';
import {fetchAppuser} from '@sidebarActions';
import {fetchTransactionhistory} from '@settingsBillingActions';
import {confirmAlert} from 'react-confirm-alert';
import toast from 'react-hot-toast';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import {cancelPlan} from '@upgradePlanActions';
import SkeletonLoader from '../../../../../../common/skeletonloader';
import IconUpgrade from '../icons/upgrade';
import BtnLoaderSvg from '@btnLoaderSvg';

const ref = React.createRef();

class Billing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_billing,
      search: '',
      iscancelBtnDisable: false,
      iscancelBtnLoader: false,
      selectionRange: '',
      showPicker: false,
      dateRange: [null, null],
      startDate: null,
      endDate: null,
    };

    // All Binded Functions
  }
  componentDidMount() {
    let {user, session} = this.props;
    this.props.fetchAppuser(session, user?.appuserid);
    this.props.fetchTransactionhistory(session, '', '', callback => {
      this.setState({loading: false});
    });
  }

  openUpgradePlan() {
    this.props.router.navigate('/upgradeplan');
  }

  handledateFilter(dates) {
    let {session} = this.props;
    this.setState({dateRange: dates});
    this.setState({startDate: dates[0]});
    this.setState({endDate: dates[1]});
    // console.log( 'dates', dates);
    if (dates && dates.length > 1) {
      if (dates[0] !== null && dates[1] !== null) {
        let obj = {};
        let startdate1 = moment(dates[0]).format('YYYY-MM-DD');
        startdate1 = startdate1 + ' ' + '00:00:00';
        let enddate1 = moment(dates[1]).format('YYYY-MM-DD');
        enddate1 = enddate1 + ' ' + '23:59:59';

        obj.startdate = startdate1;
        obj.enddate = enddate1;

        this.props.fetchTransactionhistory(session, '', obj, callback => {
          this.setState({loading: false});
        });
      } else {
        this.props.fetchTransactionhistory(session, '', '', callback => {
          this.setState({loading: false});
        });
      }
    } else {
      this.props.fetchTransactionhistory(session, '', '', callback => {
        this.setState({loading: false});
      });
    }
  }

  rendertransactionList() {
    var items = _.map(
      this.props.transactionhistorydataList.data,
      (values, index) => (
        <TransactionHistoryItem keyindex={index} values={values} />
      ),
    );
    return items;
  }

  handleSearch = event => {
    let {session, user} = this.props;
    let {search} = this.state;
    var searchKey = event.target.value;
    this.setState({search: searchKey});
    if (searchKey !== '') {
      this.props.fetchTransactionhistory(session, searchKey, '', res => {});
    } else {
      this.props.fetchTransactionhistory(session, '', '', res => {});
    }
  };

  _handlecancelPlan = (e, subId) => {
    let {session, user} = this.props;
    let obj = `subId=${subId}`;
    confirmAlert({
      title: 'Cancel Subscription',
      message: 'Are you sure you want to cancel your subscription?',
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            this.props.cancelPlan(this.props.session, obj, callback => {
              if (callback.success == 1) {
                setTimeout(() => {
                  this.props.fetchAppuser(
                    session,
                    user?.appuserid,
                    callback => {
                      toast.success(
                        'Your plan cancelled successfully, it will be reflect after this billing period',
                      );
                      this.setState({iscancelBtnDisable: false});
                      this.setState({iscancelBtnLoader: false});
                    },
                  );
                }, 2000);
              } else {
                toast.success(LocaleStrings.something_went_wrong);
                this.setState({iscancelBtnDisable: false});
                this.setState({iscancelBtnLoader: false});
              }
            });
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  };

  render() {
    let {
      memberList,
      userexistingplanData,
      user,
      appuserData,
      transactionhistorydataList,
    } = this.props;
    let {endDate, startDate, dateRange} = this.state;

    let name = '';
    let price = '';
    let details = '';
    let availableseats = 0;
    let seatcount = 'No Seat';
    let usedseats = 0;
    let expirydate = '';
    let percentage = 0;
    let contactcredit = '';
    let sku = '';
    let subId = null;
    let autorenew = null;
    let planid = '';
    let expired = false;
    let stripeplan = false;
    let downgrade_startson = '';

    const otherPlan = ['custom_trial', 'custom_monthly', 'custom_yearly', null];
    if (
      appuserData &&
      appuserData.data &&
      appuserData.data.length > 0 &&
      appuserData.data[0].client_by_clientid
    ) {
      let client_by_clientid = appuserData.data[0]?.client_by_clientid;
      if (
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid &&
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid
          .length > 0
      ) {
        let usersubscription_by_clientid =
          appuserData.data[0]?.client_by_clientid
            ?.usersubscription_by_clientid[0];
        expirydate = convertDateIntoLocal(
          usersubscription_by_clientid.expirydate,
        ).format('D MMM YYYY');
        let currentDate = moment();

        if (expirydate !== '') {
          if (currentDate.isAfter(moment(expirydate))) {
            expired = true;
          }
        }

        downgrade_startson = usersubscription_by_clientid.downgrade_startson
          ? convertDateIntoLocal(
              usersubscription_by_clientid.downgrade_startson,
            ).format('D MMM YYYY')
          : '';

        subId = usersubscription_by_clientid.transactionid;
        autorenew = usersubscription_by_clientid.autorenew;
      }
      if (
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid &&
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid
          .length > 0 &&
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid[0]
          ?.subscriptionplan_by_planid
      ) {
        let subscriptionplan_by_planid =
          appuserData.data[0]?.client_by_clientid
            ?.usersubscription_by_clientid[0]?.subscriptionplan_by_planid;
        name = subscriptionplan_by_planid.name;
        price = subscriptionplan_by_planid.price;
        details = subscriptionplan_by_planid.details;
        availableseats = subscriptionplan_by_planid.availableseats;
        sku = subscriptionplan_by_planid.sku;
        planid = subscriptionplan_by_planid.planid;
      }
      if (
        appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid &&
        appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid.length >
          0
      ) {
        let usercredit_by_clientid =
          appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid[0];
        seatcount = usercredit_by_clientid.seatcount;
        contactcredit = usercredit_by_clientid.contactcredit;
      }
      if (availableseats !== 0 && seatcount !== 'No Seat') {
        if (seatcount == 0 || seatcount == '0') {
          usedseats = availableseats;
          percentage = 100;
        } else {
          usedseats = parseInt(availableseats) - parseInt(seatcount);
          percentage = (parseInt(usedseats) / availableseats) * 100;
        }
      } else {
        usedseats = parseInt(availableseats) - 0;
        percentage = 0;
      }
    }
    var progressbarwidth = {
      width: percentage + '%',
    };
    if (sku != '') {
      if (!otherPlan.includes(sku)) {
        stripeplan = true;
      }
    }

    // console.log("appuserdata", appuserData);
    // console.log("stripeplan", stripeplan);
    // console.log("subId", subId);
    // console.log("autorenew", autorenew);
    return (
      <>
        <div className="xs:px-6 md:px-0">
          {this.state.loading ? (
            // <>
            //   <BaseLoader data={this.state.loaderDesc} />
            // </>
            <SkeletonLoader small />
          ) : (
            <>
              {appuserData.data &&
              appuserData.data[0]?.client_by_clientid
                ?.usersubscription_by_clientid &&
              appuserData.data[0]?.client_by_clientid
                ?.usersubscription_by_clientid.length > 0 ? (
                <>
                  <div className="block md:flex pb-4">
                    <div>
                      <div className="text-mainGray text-base font-medium">
                        {LocaleStrings.setting_billing_heading}
                      </div>
                      <div className="text-gray500 text-xs font-normal">
                        {LocaleStrings.setting_billing_subheading}
                      </div>
                    </div>
                    {stripeplan == true &&
                    subId != null &&
                    autorenew != null &&
                    (autorenew == 1 || autorenew == '1') ? (
                      <div className="ml-auto">
                        <div className="flex">
                          <button
                            type="button"
                            disabled={this.state.iscancelBtnDisable}
                            className={classNames(
                              this.state.iscancelBtnDisable
                                ? 'cursor-not-allowed'
                                : 'cursor-pointer',
                              'btn-light-primary',
                            )}
                            onClick={e => this._handlecancelPlan(e, subId)}>
                            <BtnLoaderSvg
                              loader={this.state.iscancelBtnLoader}
                            />
                            {LocaleStrings.setting_billing_header_btn}
                          </button>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="grid xs:grid-cols-1 md:grid-cols-2">
                    <div className="bg-white shadow sm:rounded-lg mr-4 border">
                      <div className="p-6 border-b">
                        <div className="block md:flex">
                          <div>
                            <div className="text-base	text-mainGray font-normal">
                              {name}
                            </div>
                            <div className="flex">
                              {TRIALPLANID.includes(planid) ? (
                                <div className="text-xs font-medium text-ternary">
                                  {LocaleStrings.dashboard_main_autorenew}{' '}
                                  {expirydate}
                                </div>
                              ) : (
                                <>
                                  {stripeplan == true &&
                                  subId !== null &&
                                  autorenew !== null &&
                                  (autorenew == 1 || autorenew == '1') ? (
                                    <>
                                      {' '}
                                      <div className="text-gray6B7280 text-xs font-medium">
                                        {downgrade_startson !== ''
                                          ? LocaleStrings.dashboard_main_downgrade
                                          : LocaleStrings.setting_billing_box1_subheader}
                                      </div>
                                      <div className="text-gray6B7280 text-xs ml-1">
                                        {downgrade_startson !== ''
                                          ? downgrade_startson
                                          : expirydate}
                                      </div>
                                    </>
                                  ) : (
                                    <div className="text-red-400 text-xs font-medium">
                                      {
                                        LocaleStrings.dashboard_main_head_subscriptioncancelled
                                      }{' '}
                                      {expirydate}
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                          <div className="flex ml-auto">
                            <div className="text-lg font-medium">${price}</div>
                            <div className="text-gray500 text-sm font-medium ml-1">
                              {LocaleStrings.setting_billing_box1_permonth}
                            </div>
                          </div>
                        </div>
                        <div className="mt-4">
                          <div className="">
                            {' '}
                            {usedseats} of {availableseats} team members
                          </div>
                          <div className="w-full mt-2 bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                            <div
                              className="bg-primary h-2.5 rounded-full"
                              style={progressbarwidth}></div>
                          </div>
                        </div>
                      </div>
                      <div className="px-6 py-3">
                        <div className="end-item">
                          <div
                            className="flex cursor-pointer"
                            onClick={e => this.openUpgradePlan(e)}>
                            <div className="text-primary">
                              {planid == PROFESSIONAL_YEARLY
                                ? 'View Plans'
                                : 'Upgrade'}
                              {/* {LocaleStrings.viewplan} */}
                            </div>
                            <div className="mt-1 ml-4">
                              {/* <img src="../../images/billing/upgradeplanicon.png" /> */}
                              <IconUpgrade />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* commented for now */}
                    {/* <div className="bg-white shadow sm:rounded-lg ml-4">
                                    <div className="p-6">
                                        <div className="text-lg	text-mainGray font-normal">Payment Plan</div>
                                        <div className="text-gray6B7280 text-sm mt-1">Change how you pay your plan</div>
                                        <div className="bg-white shadow sm:rounded-lg mt-3">
                                            <div className="p-4">
                                                <div className="flex">
                                                <img src="../../images/billing/stripeicon.png" />

                                                <div className="block">
                                                    
                                                </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                  </div>
                  <div className="block md:flex mt-10 justify-between flex">
                    <div className="text-base font-medium text-mainGray justify-start">
                      {LocaleStrings.setting_billing_invoice}
                    </div>
                    <div className="flex justify-end gap-2">
                      <div className="w-full text-ternary focus-within:text-secondary ">
                        {/* <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none px-3">
                          <SearchIcon className="h-5 w-5" aria-hidden="true" />
                        </div> */}
                        <input
                          id="search-field"
                          className="block border-gray-200 p-2 border-gray-300 text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm rounded-md"
                          placeholder={'Search by invoice id'}
                          type="search"
                          name="search"
                          value={this.state.search}
                          onChange={this.handleSearch}
                        />
                      </div>
                      <div>
                        <DatePicker
                          className="text-xs fixed"
                          selectsRange={true}
                          startDate={dateRange[0]}
                          endDate={dateRange[1]}
                          // maxDate={new Date()}
                          onChange={update => this.handledateFilter(update)}
                          isClearable={true}
                          dateFormat={`dd-MM-yyyy`}
                          customInput={<ExampleCustomInput ref={ref} />}
                          popperPlacement="bottom-end"
                        />
                      </div>
                    </div>
                  </div>
                  {transactionhistorydataList &&
                  transactionhistorydataList.data &&
                  transactionhistorydataList.data.length > 0 ? (
                    <div className="mt-8 flex flex-col">
                      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 md:pl-0 md:pr-1">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-xs font-medium text-gray500 sm:pl-6">
                                    {/* {LocaleStrings.setting_billing_th1} */}
                                    Invoice
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-3 py-3.5 text-left text-xs font-medium text-gray500">
                                    {LocaleStrings.setting_billing_th2}
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-3 py-3.5 text-left text-xs font-medium text-gray500">
                                    {LocaleStrings.setting_billing_th3}
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-3 py-3.5 text-left text-xs font-medium text-gray500">
                                    {LocaleStrings.setting_billing_th4}
                                  </th>
                                  <th
                                    scope="col"
                                    className="relative py-3.5 pl-3 pr-4 sm:pr-6 text-xs font-medium text-gray500">
                                    {LocaleStrings.setting_billing_th5}
                                  </th>
                                  <th
                                    scope="col"
                                    className="relative py-3.5 pl-3 pr-4 sm:pr-6"></th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200 bg-white">
                                {this.rendertransactionList()}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="center-item lg:my-48 text-lg text-ternary">
                      {LocaleStrings.no_data_found}
                    </div>
                  )}
                </>
              ) : (
                <div className="center-item lg:my-48 text-lg text-ternary">
                  {LocaleStrings.setting_billing_subscribe_toseebilling}
                </div>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

var mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  userexistingplanData: state.userexistingplanData,
  appuserData: state.appuserData,
  transactionhistorydataList: state.transactionhistorydataList,
});

export default connect(mapStateToProps, {
  fetchAppuser,
  fetchTransactionhistory,
  cancelPlan,
})(Billing);

const ExampleCustomInput = React.forwardRef(
  ({value, placeholderText, onClick}, ref) => {
    var splitVal = value.split(' - ');
    return (
      <div className="pr-4 w-[220px] h-10" onClick={onClick} ref={ref}>
        <button type="button" className="btn-white-delete px-4 ml-0 ">
          {splitVal?.length > 1 ? (
            <span className="text-xs">
              {' '}
              {splitVal[0]} to {splitVal[1]}{' '}
            </span>
          ) : (
            <>
              <img className="pr-2" src="../../images/calender.png"></img>
              <span className="text-sm">Filter by Date</span>
            </>
          )}
        </button>
      </div>
    );
  },
);

import React from 'react';
import {connect} from 'react-redux';
import {classNames} from '@commonFunction';
import SocialLinks from '../../search/results/cells/components/sociallinks';

import {labelNotAssigned} from '../constants';
import {formattedDateIntoLocal} from '@commonFunction';
import IconDelete from '../icons/delete';
import CompanyBlock from '../../search/results/cells/components/companyblock';

import LabelDropdownBlock from '../components/labeldropdownblock';
import {toTitleCase} from '../../../../common/constants';
import ContactDetails from '../../search/results/cells/components/contactdetails';
import {addCRMLead, FILTER_TYPE_MYLIST, zapierHook} from '../../search/actions';

function MyListPeopleCell(props) {
  const {person, selected, onCheckUnCheck} = props;
  const {contactname, jobtitle, company, tag, createdon} = person;

  const {
    linkedin,
    twitter,
    facebook,
    exported_to_zoho,
    exported_to_salesforce,
    exported_to_webhook,
  } = person;
  return (
    <tr
      key={contactname}
      className={classNames('', selected ? 'bg-gray-50' : undefined)}>
      <td className="group relative py-2">
        {selected && (
          <div className="absolute inset-y-0 left-0 w-0.5 bg-primary" />
        )}
        <input
          type="checkbox"
          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-500 text-primary group-hover:cursor-pointer"
          value={contactname}
          checked={selected}
          onChange={e => onCheckUnCheck(e.target.checked)}
        />
      </td>
      <td
        className={classNames(
          'group py-4 pr-3 hover:cursor-pointer relative px-4 py-2',
        )}
        onClick={() => {
          onCheckUnCheck(!selected);
        }}>
        <div className="relative flex gap-2 items-center">
          <div
            className={classNames(
              'whitespace-nowrap text-xs font-medium w-fit text-gray-900 truncate',
            )}>
            {contactname}
          </div>

          <div>
            <SocialLinks
              linkedin={linkedin}
              twitter={twitter}
              facebook={facebook}
              exported_to_zoho={exported_to_zoho}
              exported_to_salesforce={exported_to_salesforce}
              exported_to_webhook={exported_to_webhook}
            />
          </div>
        </div>

        {/* <div
          className="absolute right-2 top-6 group-hover:opacity-100 opacity-0"
          onClick={() => {
            navigator.clipboard.writeText(contactname);
            props.onCopySuccess();
          }}>
          <IconCopy />
        </div> */}
      </td>
      <td className="group whitespace-wrap px-3 py-2 hover:cursor-pointer relative">
        <div className="text-xs text-gray-500">{toTitleCase(jobtitle)}</div>
        {/* <div
          className="absolute right-2 top-7 group-hover:opacity-100 opacity-0"
          onClick={() => {
            navigator.clipboard.writeText(jobtitle);
            props.onCopySuccess();
          }}>
          <IconCopy />
        </div> */}
      </td>

      <td className="whitespace-nowrap px-3">
        <ContactDetails {...props} filterType={FILTER_TYPE_MYLIST} />
        {/* <div className="flex gap-2 relative">
          {contactdetails.filter(contact => contact.type === 'directdial')
            .length > 0 ? (
            <div className="group relative">
              <ContactBlock
                contacts={contactdetails.filter(
                  contact => contact.type === 'directdial',
                )}
                contactname={contactname}
                contacttype="phone"
                onUpgradePlan={() => props.onUpgradePlan()}
                onBuyAddon={() => props.onBuyAddon()}
                person={person}
                onRevealCompletion={updatedPerson => {
                  const contactIndex = props.filteredPeople.data.findIndex(
                    person => person.peopleid === updatedPerson.peopleid,
                  );
                  props.filteredPeople.data[contactIndex] = updatedPerson;
                  props.updatePeople(props.filteredPeople);
                }}
              />
            </div>
          ) : null}
          <div className="group relative">
            <ContactBlock
              contacts={contactdetails.filter(
                contact =>
                  contact.type === 'personalemail' ||
                  contact.type === 'businessemail',
              )}
              contactname={contactname}
              contacttype="email"
              onUpgradePlan={() => props.onUpgradePlan()}
              onBuyAddon={() => props.onBuyAddon()}
              person={person}
              onRevealCompletion={updatedPerson => {
                const contactIndex = props.filteredPeople.data.findIndex(
                  person => person.peopleid === updatedPerson.peopleid,
                );
                props.filteredPeople.data[contactIndex] = updatedPerson;
                props.updatePeople(props.filteredPeople);
              }}
            />
          </div>
        </div> */}
      </td>
      <td className="group px-3 py-2 hover:cursor-pointer relative">
        <CompanyBlock company={company} />
      </td>
      <td className="px-3 py-2 text-xs text-gray-500 justify-items-center">
        <div className="justify-start items-start gap-1 inline-flex relative">
          <LabelDropdownBlock
            tag={tag}
            labelNotAssigned={labelNotAssigned}
            onLabelSelection={label => {
              props.onLabelSelection(label);
            }}
          />
          {/* <div
            className="px-3 py-2 rounded-2xl justify-start items-center gap-1 flex hover:cursor-pointer hover:scale-105"
            style={{
              backgroundColor: tag
                ? `${tag['color']}20`
                : `${labelNotAssigned['color']}20`,
            }}
            onClick={() => {
              setShowLabelList(!showLabelList);
            }}>
            <div
              className="text-center text-xs font-medium font-['Inter'] leading-none"
              style={{
                color: tag ? `${tag['color']}` : `${labelNotAssigned['color']}`,
              }}>
              {tag ? tag.title : labelNotAssigned.title}
            </div>
            <div className="w-5 h-5 px-0.5 justify-center items-center flex">
              <ChevronDownIcon
                className="h-4 w-4"
                aria-hidden="true"
                style={{
                  color: tag
                    ? `${tag['color']}`
                    : `${labelNotAssigned['color']}`,
                }}
              />
            </div>
          </div>
          <div
            className={classNames(
              'absolute left-0 top-10 transition duration-300 ease-in z-50',
              showLabelList ? 'visible' : 'invisible',
            )}>
            <LabelsDropdown
              onLabelSelection={label => {
                setShowLabelList(false);
                props.onLabelSelection(label);
              }}
            />
          </div> */}
        </div>
      </td>
      <td className="px-3 py-2 text-xs text-gray-500 justify-items-center">
        {formattedDateIntoLocal(createdon)}
      </td>
      <td className="py-2 pl-3 justify-items-center">
        <div className="flex justify-end mr-8 gap-2">
          {/* <div onClick={() => {}}>
            <IconExport />
          </div> */}
          <div
            onClick={() => {
              props.onDeleteFromList(person);
            }}>
            <IconDelete />
          </div>
        </div>
      </td>
    </tr>
  );
}

function mapStateToProps(state) {
  var {session, user} = state;
  return {
    session,
    user,
    filteredPeople: state.filteredPeople,
    availableLabels: state.availableLabels,
    crmDetails: state.crmDetails,
  };
}

export default connect(mapStateToProps, {
  zapierHook,
  addCRMLead,
})(MyListPeopleCell);

import {INSTANCE_URL} from '@constant';
import {postRequest} from '@networkCall';
import {TOGGLE_FILTERS_SIDE_POPUP} from '../../search/actions';
import {
  defaultCompanyFieldMapping,
  defaultPeopleFieldMapping,
} from '../reducers';

export const CSV_ENRICH_LISTS = 'CSV_ENRICH_LISTS';
export const TOGGLE_CSV_SELECTION_POPUP = 'TOGGLE_CSV_SELECTION_POPUP';
export const TOGGLE_CSV_ANALYSIS = 'TOGGLE_CSV_ANALYSIS';
export const HEADER_MAPPING_SELECTION = 'HEADER_MAPPING_SELECTION';
export const PEOPLE_STANDARD_FIELD_SELECTION =
  'PEOPLE_STANDARD_FIELD_SELECTION';
export const COMPANY_STANDARD_FIELD_SELECTION =
  'COMPANY_STANDARD_FIELD_SELECTION';
export const CSV_ENRICH_INFO = 'CSV_ENRICH_INFO';
export const CSV_UPLOAD_INFO = 'CSV_UPLOAD_INFO';
export const COMPANY_HEADER_MAPPING_SELECTION =
  'COMPANY_HEADER_MAPPING_SELECTION';

export const fetchCSVList = (session, params, callback) => {
  const url = `${INSTANCE_URL}/api/v2/csvenrichment/listing`;

  const values = {
    ...params,
    limit: 25,
  };
  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      res => {
        dispatch({
          type: CSV_ENRICH_LISTS,
          payload: {
            totalcount: res.totalcount,
            pages: res.numpages,
            data: res.data,
          },
        });
        callback({success: 1});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};

export const uploadCSV = (session, params, callback) => {
  const url = `${INSTANCE_URL}/api/v2/csvenrichment/uploadcsv`;

  return dispatch => {
    postRequest(
      url,
      params,
      session,
      dispatch,
      res => {
        if (res.success) {
          dispatch({
            type: CSV_UPLOAD_INFO,
            payload: res,
          });
          callback({success: 1});
        } else {
          callback({
            success: 0,
            message: res.desc || 'Unknown error occurred',
          });
        }
      },
      error => {
        callback({
          success: 0,
          message: error.message || 'Failed to upload file',
        });
      },
    );
  };
};

export const fetchCSVInfo = (session, params, callback) => {
  const url = `${INSTANCE_URL}/api/v2/csvenrichment/getresponse`;

  return dispatch => {
    postRequest(
      url,
      params,
      session,
      dispatch,
      res => {
        dispatch({
          type: CSV_ENRICH_INFO,
          payload: res,
        });
        callback({success: 1});
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};

export function toggleCSVSelectionPopup(payload) {
  return dispatch => {
    dispatch({
      type: TOGGLE_CSV_SELECTION_POPUP,
      payload,
    });
  };
}

export function selectMapping(payload) {
  return dispatch => {
    dispatch({
      type: HEADER_MAPPING_SELECTION,
      payload,
    });
  };
}

export function selectCompanyMapping(payload) {
  return dispatch => {
    dispatch({
      type: COMPANY_HEADER_MAPPING_SELECTION,
      payload,
    });
  };
}

export function togglePeopleSelectStandardFields(payload) {
  return dispatch => {
    dispatch({
      type: PEOPLE_STANDARD_FIELD_SELECTION,
      payload,
    });
  };
}

export function toggleCompanySelectStandardFields(payload) {
  return dispatch => {
    dispatch({
      type: COMPANY_STANDARD_FIELD_SELECTION,
      payload,
    });
  };
}

export function clearSelections() {
  return dispatch => {
    dispatch({
      type: COMPANY_HEADER_MAPPING_SELECTION,
      payload: defaultCompanyFieldMapping,
    });
    dispatch({
      type: HEADER_MAPPING_SELECTION,
      payload: defaultPeopleFieldMapping,
    });
    dispatch({
      type: CSV_ENRICH_INFO,
      payload: {},
    });
  };
}

export function toggleCSVAnalysis(payload) {
  return dispatch => {
    dispatch({
      type: TOGGLE_CSV_ANALYSIS,
      payload,
    });
  };
}

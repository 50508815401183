import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {arrayPush, FieldArray, reduxForm, getFormValues} from 'redux-form';
import DropdownPair from './DropdownPair';
import LocaleStrings from '@language';
import {classNames} from '@commonFunction';
import toast, {Toaster} from 'react-hot-toast';
import {updateMappedFields, fetchCRMDetails} from '../../../../search/actions';
import {useMappingSelectionContext} from './selectioncontext';
import {useClickAway} from 'react-use';
import BtnLoaderSvg from '@btnLoaderSvg';

function addField(selectedValue) {
  return dispatch => {
    dispatch(arrayPush('mappingForm', 'mappings', selectedValue));
  };
}

function MappingForm(props) {
  const {
    defaultMappings,
    zohoOptions,
    kipploOptions,
    closeModal,
    handleSubmit,
    crmDetails,
    modalstate,
    salesforceOptions,
    initialValues,
    selectedModule,
  } = props;
  const [selectedKipplo, setSelectedKipplo] = useState([]);
  const [selectedZoho, setSelectedZoho] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [showCustomDropdown, setShowCustomDropdown] = useState(false);

  const context = useMappingSelectionContext();

  // useEffect(() => {
  //   setSelectedKipplo(defaultMappings.map(mapping => mapping.kipplo));
  //   setSelectedZoho(defaultMappings.map(mapping => mapping.zoho));
  // }, []);

  useEffect(() => {
    const initialKipplo = [];
    const initialZoho = [];
    let initialMappings = [];

    const dataKey =
      modalstate === 'salesforce'
        ? `sales_${selectedModule.slice(0, -1)}_data`
        : `zoho_${selectedModule.slice(0, -1)}_data`;
    //console.log('dataKey', dataKey);
    //console.log('crmDetails', crmDetails);
    if (
      modalstate == 'zoho' &&
      selectedModule == 'leads' &&
      crmDetails &&
      Object.keys(crmDetails.zoho_mapped_data).length > 0
    ) {
      const zoho_mapped_data = Object.entries(crmDetails.zoho_mapped_data).map(
        ([zoho, kipplo]) => ({
          kipplo,
          zoho,
        }),
      );
      console.log('zoho_mapped_data if', zoho_mapped_data);
      initialMappings = zoho_mapped_data;
    } else if (
      modalstate === 'zoho' &&
      crmDetails &&
      Object.keys(crmDetails[dataKey]).length > 0
    ) {
      // Handle Zoho module data if it's available
      const zohoModuleData = Object.entries(crmDetails[dataKey]).map(
        ([zoho, kipplo]) => ({
          kipplo,
          zoho,
        }),
      );
      initialMappings = zohoModuleData;
    } else {
      initialMappings = defaultMappings;
    }

    // else {
    //   let finalArray = defaultMappings;
    //   finalArray = finalArray.slice(0, 1);
    //   initialMappings = finalArray;
    // }

    if (
      modalstate == 'salesforce' &&
      selectedModule == 'leads' &&
      crmDetails &&
      Object.keys(crmDetails.sales_mapped_data).length > 0
    ) {
      const sales_mapped_data = Object.entries(
        crmDetails.sales_mapped_data,
      ).map(([zoho, kipplo]) => ({
        kipplo,
        zoho,
      }));

      initialMappings = sales_mapped_data;
    } else if (
      modalstate === 'salesforce' &&
      crmDetails &&
      crmDetails.hasOwnProperty(dataKey) &&
      Object.keys(crmDetails[dataKey]).length > 0
    ) {
      // Handle Salesforce module data if it's available
      const salesModuleData = Object.entries(crmDetails[dataKey]).map(
        ([zoho, kipplo]) => ({
          kipplo,
          zoho,
        }),
      );
      initialMappings = salesModuleData;
    } else {
      initialMappings = defaultMappings;
    }

    if (initialMappings.length > 0) {
      initialMappings.forEach(mapping => {
        initialKipplo.push(mapping.kipplo);
        initialZoho.push(mapping.zoho);
      });
    }

    setSelectedKipplo(initialKipplo);
    setSelectedZoho(initialZoho);
  }, [crmDetails, defaultMappings, modalstate, selectedModule]);

  const handleFormSubmit = values => {
    const {mappings} = values;
    let formValidate = true;
    if (props.edit == false) {
      let salesforceToAssign = ['LastName', 'Company'];
      let zohoToAssign = ['Last_Name'];

      const zohoValues = values.mappings.map(entry => entry.zoho);
      let counter = 0;
      let filterArr = modalstate == 'zoho' ? zohoToAssign : salesforceToAssign;
      // filterArr.forEach(field => {
      //   if (!zohoValues.includes(field)) {
      //     counter++;
      //   }
      // });
      if (counter > 0) {
        formValidate = false;
      }
    }

    if (formValidate) {
      setError(false);
      let leadOption = modalstate == 'zoho' ? zohoOptions : salesforceOptions;
      // console.log('zohoOptions', zohoOptions);
      // console.log('leadOption', leadOption);
      // console.log('kipploOptions', kipploOptions);
      // debugger;
      const jsonResult = mappings.reduce((acc, {kipplo, zoho}) => {
        if (kipplo && zoho) {
          acc[leadOption.find(option => option.value === zoho).value] =
            kipploOptions.find(option => option.value === kipplo).value;
        }
        return acc;
      }, {});

      let data = {};
      data.crmtype = modalstate == 'zoho' ? 'zoho' : 'salesforce';
      data.mapped_data = JSON.stringify(jsonResult);
      data.module = selectedModule;
      //console.log('data', data);
      setLoading(true);
      props.updateMappedFields(props.session, data, res => {
        //console.log('res', res);
        setLoading(false);
        if (res.success == 1) {
          props.fetchCRMDetails(props.session, resp => {
            //console.log('resp', resp);
            toast.success('Updated Successfully');
          });
        } else {
          toast.error(LocaleStrings.something_went_wrong);
        }
        closeModal();
      });
    } else {
      setError(true);
    }

    // console.log('Generated JSON:', jsonResult);
  };

  const handleAddFieldClick = () => {
    console.log('selectedKipplo.length', selectedKipplo.length);
    setShowCustomDropdown(!showCustomDropdown);
    //context.selectIndex(selectedKipplo.length);
  };

  const handleOptionSelect = (selectedValue, index) => {
    const value = {kipplo: selectedValue, zoho: ''};
    let counter = index;
    if (props.formValues.hasOwnProperty('mappings')) {
      counter = props.formValues['mappings'].length;
    }

    setSelectedKipplo(prev => {
      const newSelected = [...prev];
      newSelected[counter] = selectedValue;
      return newSelected;
    });

    props.addField(value);
    context.addField(value);
    setShowCustomDropdown(false);
  };

  const listRef = useRef(null);

  useClickAway(
    listRef,
    () => {
      setShowCustomDropdown(false);
    },
    ['mouseup'],
  );

  useEffect(() => {
    if (props.initialValues) {
      context.updateMappedFields(props.initialValues.mappings);
    }
  }, [props.initialValues]);

  const dynamicKey = `${modalstate}_${selectedModule}_fields`;

  console.log('dynamicKey', dynamicKey);
  console.log('selectedModule', selectedModule);
  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="py-2">
        <div className="flex gap-6 my-4">
          <div className="w-2/5 text-left py-2.5 px-2  rounded-md shadow-sm text-sm font-medium  bg-primaryHover text-primary">
            {LocaleStrings.kipplo_fields}
          </div>
          <div className="w-2/5 text-left py-2.5 px-2   rounded-md shadow-sm text-sm font-medium  bg-primaryHover  text-primary">
            {LocaleStrings[dynamicKey]}
          </div>
        </div>
        {error ? (
          <div className="text-red-600">{LocaleStrings.mapping_error}</div>
        ) : (
          ''
        )}
        <div className="h-96 overflow-auto">
          <FieldArray
            name="mappings"
            component={DropdownPair}
            kipploOptions={kipploOptions}
            zohoOptions={modalstate == 'zoho' ? zohoOptions : salesforceOptions}
            selectedKipplo={selectedKipplo}
            setSelectedKipplo={setSelectedKipplo}
            selectedZoho={selectedZoho}
            setSelectedZoho={setSelectedZoho}
            modalstate={modalstate}
            selectedModule={selectedModule}
          />
        </div>
        <div className="mt-5 flex justify-between">
          <div className="inline-block relative">
            <span
              className="btn-light-primary cursor-pointer"
              style={{
                width: 100,
                position: 'relative',
                display: 'inline-block',
              }}
              onClick={() => {
                handleAddFieldClick();
              }}>
              + Add Fields
            </span>
            {showCustomDropdown && (
              <div
                ref={listRef}
                className="absolute  overflow-y-auto bg-white border border-gray-300 rounded-md shadow-lg z-10 left-0 -top-[300px] min-w-60 max-h-[300px]">
                <ul>
                  {kipploOptions
                    .filter(option => !selectedKipplo.includes(option.value))
                    .map(option => (
                      <li
                        key={option.value}
                        className="cursor-pointer hover:bg-gray-100 px-3 py-2 rounded text-base sm:text-sm"
                        onClick={() => {
                          handleOptionSelect(
                            option.value,
                            context.selectedIndex,
                          );
                        }}>
                        {option.label}
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </div>
          <div className="flex gap-2">
            <button
              disabled={loading}
              //onClick={e => this.fetchCompanydetails(e)}
              className={classNames(
                loading ? 'cursor-not-allowed opacity-20' : '',
                'btn-primary',
              )}
              style={{width: 120}}>
              <BtnLoaderSvg loader={loading} />
              {LocaleStrings.save}
            </button>
            <button
              type="button"
              onClick={props.closeModal}
              className="px-4 py-2 text-xs border border-gray-200 rounded-lg hover:bg-gray-100 text-black hover:cursor-pointer">
              {LocaleStrings.cancel}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

function validate(values, ownProps) {
  const salesforceToAssign = ['LastName', 'Company'];
  const zohoToAssign = ['Last_Name'];
  const errors = {};
  //console.log('values', values);
  // const zohoValues =
  //   values.mappings && values.mappings.map(entry => entry.zoho);

  // salesforceToAssign.forEach(field => {
  //   if (!zohoValues.includes(field)) {
  //     //errors.zohoField1 = `Mapping error: '${field}' is missing from  mapping.`;
  //   }
  // });

  return errors;
}

function mapStateToProps(state, ownProps) {
  var {session, user} = state;
  console.log(ownProps);
  let finalArray = [];
  const salesforceToAssign = ['LastName', 'Company'];
  const zohoToAssign = ['Last_Name'];
  let edit = true;
  const isSalesforce = ownProps.modalstate === 'salesforce';
  const isZoho = ownProps.modalstate === 'zoho';
  const module = ownProps.selectedModule;
  const crmDetails = state.crmDetails || {};

  finalArray = ownProps.defaultMappings;

  if (isSalesforce) {
    const dataKey =
      module === 'leads'
        ? 'sales_mapped_data'
        : `sales_${module.slice(0, -1)}_data`;
    const dataObj = crmDetails[dataKey] || {};
    if (Object.keys(dataObj).length === 0) {
      // If no data, assign Zoho fields to defaultMappings
      finalArray = finalArray.slice(0, 1);
      for (let i = 0; i < salesforceToAssign.length; i++) {
        if (i < finalArray.length) {
          finalArray[i].zoho = salesforceToAssign[i];
        }
      }
      edit = false;
    } else {
      // Map Zoho entries
      finalArray = Object.entries(dataObj).map(([key, value]) => ({
        kipplo: value,
        zoho: key,
      }));
    }
  } else if (isZoho) {
    // Zoho logic
    const dataKey =
      module === 'leads'
        ? 'zoho_mapped_data'
        : `zoho_${module.slice(0, -1)}_data`;
    console.group('dataKey', dataKey);
    const dataObj = crmDetails[dataKey] || {};
    console.group('dataObj', dataObj);
    if (Object.keys(dataObj).length === 0) {
      // If no data, assign Zoho fields to defaultMappings
      finalArray = finalArray.slice(0, 1);
      for (let i = 0; i < zohoToAssign.length; i++) {
        if (i < finalArray.length) {
          finalArray[i].zoho = zohoToAssign[i];
        }
      }
      edit = false;
    } else {
      // Map Zoho entries
      finalArray = Object.entries(dataObj).map(([key, value]) => ({
        kipplo: value,
        zoho: key,
      }));
    }
  }

  //console.group('finalArray', finalArray);

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    appuserData: state.appuserData,
    initialValues: {
      mappings: finalArray,
    },
    crmDetails: state.crmDetails,
    edit: edit,
    formValues: getFormValues('mappingForm')(state),
  };
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({updateMappedFields, fetchCRMDetails, addField}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    validate,
    form: 'mappingForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(MappingForm),
);
